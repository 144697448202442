<template>
  <div class="set-page">
    <div class="set-rule">
      <div class="rule-article" v-html="about">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SetRule",
  data () {
    return {
      about:''
    }
  },
  methods:{
    getAbout () {
      this.$axios.aboutUs().then(res=>{
        this.about = res.data
      })
    }
  },
  created () {
    this.getAbout()
  }
};
</script>

<style scoped lang="less">
.set-rule{
  background:#fff;padding:30px 40px;
  .rule-article{
    color:#666;font-size:14px;line-height:2;
    p{line-height:2;}
  }
}
</style>